.menuList {
    padding: 15px;
    height: 300px;
    overflow: auto;
}
.menuList::-webkit-scrollbar {
    display: none;
}
.menuList{
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
.menuList h4 {
    font-size: 18px;
    text-transform: capitalize;
    color: #d3c0dc;
}


.menuList ul {
    padding: 0;
    margin: 0;
}
.menuList ul li a{
    padding: 8px 0;
}

.perent{
    position: relative;
}