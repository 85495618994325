.bannerDetail {
    padding: 50px 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
    position: relative;
}

.bannerSection .slick-slider.slick-initialized .slick-prev {
    left: 25px;
    z-index: 111;
}

.bannerSection .slick-slider.slick-initialized .slick-next {
    right: 25px;
    z-index: 111;
}

.bannerSection>div>div>div>div {
    margin: 0 !important;
}


.categoryItemSlider>.slick-initialized {
    height: 500px !important;
}

.bannerSection>div>div {
    height: 364px !important;
}

/* .bannerDetail:before { */
    /* width: 100%; */
    /* height: 100%; */
    /* position: absolute; */
    /* left: 0; */
    /* top: 0; */
    /* background: #0e0e0e; */
    /* fallback for old browsers */
    /* background: -webkit-linear-gradient(to right, #171717, #ffffff00); */
    /* Chrome 10-25, Safari 5.1-6 */
    /* background: linear-gradient(to right, #171717, #ffffff00); */
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    /* z-index: 1; */
    /* content: ""; */
/* } */

.bannerContent {
    position: relative;
    z-index: 111;
    background-size: 100%;
    height: 50vh;
}

.bannerContent .title h6 {
    color: #b1b1b1;
    text-transform: capitalize;
}

.bannerContent .title h3 {
    font-size: 40px;
    text-transform: capitalize;
    color: #fb641b;
}

/* .percentOff {
    display: flex;
    align-items: center;
    column-gap: 20px;
} */
.bannerContent .title h1 {
    color: #fff;
    font-size: 80px;
    text-transform: uppercase;
    line-height: 60px;
    margin-bottom: 40px;

}

.bannerContent .priceSection {
    display: flex;
    align-items: center;
}

.bannerContent .priceSection .text {
    color: rgb(160, 160, 160);
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 1px;
    font-weight: 600;
}

.bannerContent .priceSection .number {
    padding: 0 8px;
    color: #fb641b;
    font-weight: bold;
    font-size: 24px;
}

.shopNow a {
    display: inline-block;
    background-color: #fb641b;
    color: #fff;
    padding: 10px 26px;
    text-transform: uppercase;
}